import React from 'react';
import bgHeroWebp from '../../../assets/image/whitepaper-hero.webp';
import bgHeroJpg from '../../../assets/image/whitepaper-hero.jpg';
import FormSection from './Form';

const Header = ({ whitepaper }) => {
  return (
    <div className='relative'>
      <div className='relative z-[1] container mx-auto grid'></div>
      <div className='absolute w-full h-[80vh] max-h-[836px] top-0 left-0 z-0'>
        <picture>
          <source
            srcSet={bgHeroWebp}
            type='image/webp'
            className='w-full h-full object-cover object-center'
          />
          <img
            src={bgHeroJpg}
            alt='hero-bg'
            className='w-full h-full object-cover object-center'
          />
        </picture>
      </div>
      <div className='relative pt-[200px]'>
        <FormSection whitepaper={whitepaper} />
      </div>
    </div>
  );
};

export default Header;
