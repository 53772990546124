import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import '../../../styles/form.css';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';
import Check from '../../../assets/image/check-icon.svg';
import pdf1 from '../../../static/whitepapers/desarrollo_de_las_ciudades_inteligentes_en_mexico.pdf';
import pdf2 from '../../../static/whitepapers/comienza_a_utilizar_ia_en_tu_empresa.pdf';
import pdf3 from '../../../static/whitepapers/vendiendo_con_ai.pdf';

const whitepapers = {
  1: {
    id: 1,
    file: pdf1,
  },
  2: {
    id: 2,
    file: pdf2,
  },
  3: {
    id: 3,
    file: pdf3,
  },
};

export default function FormSection({ whitepaper }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [jobTitleTouched, setJobTitleTouched] = useState(false);
  const [companyNameTouched, setCompanyNameTouched] = useState(false);
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isSending, setIsSending] = useState(false);

  const { t } = useTranslation();

  const validateName = () => {
    if (name && /\d/.test(name)) {
      return t('form.validations.error4');
    }
    return '';
  };

  const validateEmail = () => {
    if (email && !/^\S+@\S+\.\S+$/.test(email)) {
      return t('form.validations.error5');
    }
    return '';
  };

  //PhoneNumber with at least 10 digits
  const validatePhoneNumber = () => {
    if (phoneNumber && !/^\d{10,}$/.test(phoneNumber)) {
      return t('form.validations.error8');
    }
    return '';
  };

  const validateJobTitle = () => {
    if (jobTitle && /\d/.test(jobTitle)) {
      return t('form.validations.error9');
    }
    return '';
  };

  const isFormValid = () => {
    return (
      name.trim() !== '' &&
      email.trim() !== '' &&
      jobTitle.trim() !== '' &&
      companyName.trim() !== '' &&
      phoneNumber.trim() !== '' &&
      !validateName() &&
      !validateEmail() &&
      !validateJobTitle() &&
      !validatePhoneNumber()
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      console.error('The form is not valid');
      setError(t('form.validations.error10'));
      return;
    }

    try {
      setIsSending(true);
      const response = await fetch(`https://api.smarttie.ca`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'emHEzTvF1]0B=[u.Q]t$%%da/9P7q-',
        },
        body: JSON.stringify({
          name,
          email,
          jobTitle,
          companyName,
          phoneNumber,
          whitepaper: whitepaper.title,
          origin: 'whitepaper',
        }),
      });

      // Download a file from src/assets/whitepapers
      const link = document.createElement('a');
      link.href = whitepapers[whitepaper.jsonId].file;
      link.setAttribute('download', `${whitepaper.title}.pdf`); // Para forzar la descarga
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    } finally {
      setName('');
      setEmail('');
      setJobTitle('');
      setCompanyName('');
      setPhoneNumber('');
      setNameTouched(false);
      setEmailTouched(false);
      setJobTitleTouched(false);
      setCompanyNameTouched(false);
      setPhoneNumberTouched(false);
      setIsSending(false);
      setError('');
    }
    setFormSubmitted(true);
  };

  if (whitepaper === null) {
    return null;
  }

  return (
    <div className='flex items-center justify-center' id='contact'>
      <div className='container mx-auto flex flex-col lg:flex-row justify-between my-14'>
        <div className='w-full lg:max-w-[55%] mb-4 lg:mb-0'>
          <AnimationWrapper from='left'>
            <div className='text-start '>
              <h1 className='text-4xl md:max-w-2xl md:text-5xl xl:text-6xl font-bold mb-4 text-white line'>
                {whitepaper.title}
              </h1>
              {whitepaper.description.map((desc, index) => (
                <p key={index} className='text-white text-lg md:text-xl mb-4'>
                  {desc}
                </p>
              ))}
              <h2 className='text-2xl md:max-w-2xl md:text-3xl xl:text-4xl font-bold mb-4 text-white line'>
                Contenido
              </h2>
              <ol className='pl-12'>
                {whitepaper.content.map((content, index) => (
                  <li
                    key={index}
                    className='text-white text-lg md:text-xl mb-1 list-decimal'
                  >
                    {content.title}

                    {content.subcontent && (
                      <ul className='pl-8'>
                        {content.subcontent.map((subContent, index) => (
                          <li
                            key={index}
                            className='text-white md:text-lg mb-1 list-disc'
                          >
                            {subContent}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ol>
            </div>
          </AnimationWrapper>
        </div>
        <div className='max-w-[500px] w-full flex-auto mx-auto lg:mx-0 lg:ml-4'>
          <AnimationWrapper from='right' delay={0.3}>
            {formSubmitted ? (
              <div className='flex flex-col justify-center items-center p-8 rounded-lg bg-white/10'>
                <img src={Check} alt='check' />
                <p className='text-white text-3xl text-center font-bold'>
                  {t('form.submitMessage')}
                </p>
              </div>
            ) : (
              <form
                className='p-8 rounded-lg bg-white/10'
                onSubmit={handleSubmit}
              >
                <div className='mb-2'>
                  <label
                    htmlFor='name'
                    className='block text-white mb-2 uppercase'
                  >
                    {t('form.name')}
                  </label>
                  <input
                    type='text'
                    id='name'
                    placeholder={
                      !nameTouched
                        ? t('form.name')
                        : name
                        ? validateName()
                        : t('form.validations.error1')
                    }
                    name='name'
                    className={`w-full md:w-full px-4 py-2 border rounded-md text-black text-sm ${
                      (!name || validateName()) && nameTouched
                        ? 'border-red-500'
                        : ''
                    }`}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameTouched(true);
                    }}
                    required
                  />
                  {nameTouched && validateName() && (
                    <p className='text-pink-300'>{validateName()}</p>
                  )}
                </div>
                <div className='mb-2'>
                  <label
                    htmlFor='jobTitle'
                    className='block text-white mb-2 uppercase'
                  >
                    {t('form.jobTitle')}
                  </label>
                  <input
                    type='text'
                    id='jobTitle'
                    placeholder={
                      !jobTitleTouched
                        ? t('form.jobTitle')
                        : jobTitle
                        ? validateEmail()
                        : t('form.validations.error2')
                    }
                    name='jobTitle'
                    className={`w-full md:w-full px-4 py-2 border rounded-md text-black text-sm ${
                      (!jobTitle || validateJobTitle()) && jobTitleTouched
                        ? 'border-red-500'
                        : ''
                    }`}
                    value={jobTitle}
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                      setJobTitleTouched(true);
                    }}
                    required
                  />
                  {jobTitleTouched && validateJobTitle() && (
                    <p className='text-pink-300'>{validateJobTitle()}</p>
                  )}
                </div>
                <div className='mb-2'>
                  <label
                    htmlFor='companyName'
                    className='block text-white mb-2 uppercase'
                  >
                    {t('form.companyName')}
                  </label>
                  <input
                    type='text'
                    id='companyName'
                    placeholder={
                      !companyNameTouched
                        ? t('form.companyName')
                        : companyName
                        ? ''
                        : t('form.validations.error2')
                    }
                    name='companyName'
                    className={`w-full md:w-full px-4 py-2 border rounded-md text-black text-sm ${
                      !companyName && companyNameTouched ? 'border-red-500' : ''
                    }`}
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setCompanyNameTouched(true);
                    }}
                    required
                  />
                </div>
                <div className='mb-2'>
                  <label
                    htmlFor='email'
                    className='block text-white mb-2 uppercase'
                  >
                    {t('form.email')}
                  </label>
                  <input
                    type='email'
                    id='email'
                    placeholder={
                      !emailTouched
                        ? t('form.email')
                        : email
                        ? validateEmail()
                        : t('form.validations.error2')
                    }
                    name='email'
                    className={`w-full md:w-full px-4 py-2 border rounded-md text-black text-sm ${
                      (!email || validateEmail()) && emailTouched
                        ? 'border-red-500'
                        : ''
                    }`}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailTouched(true);
                    }}
                    required
                  />
                  {emailTouched && validateEmail() && (
                    <p className='text-pink-300'>{validateEmail()}</p>
                  )}
                </div>
                <div className='mb-2'>
                  <label
                    htmlFor='phoneNumber'
                    className='block text-white mb-2 uppercase'
                  >
                    {t('form.phoneNumber')}
                  </label>
                  <input
                    type='text'
                    id='phoneNumber'
                    placeholder={
                      !phoneNumberTouched
                        ? t('form.phoneNumber')
                        : phoneNumber
                        ? validatePhoneNumber()
                        : t('form.validations.error2')
                    }
                    name='phoneNumber'
                    className={`w-full md:w-full px-4 py-2 border rounded-md text-black text-sm ${
                      (!phoneNumber || validatePhoneNumber()) &&
                      phoneNumberTouched
                        ? 'border-red-500'
                        : ''
                    }`}
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setPhoneNumberTouched(true);
                    }}
                    required
                  />
                  {phoneNumberTouched && validatePhoneNumber() && (
                    <p className='text-pink-300'>{validatePhoneNumber()}</p>
                  )}
                </div>
                {error !== '' && <p className='text-pink-300'>{error}</p>}
                <button
                  type='submit'
                  className={`w-full md:w-auto mt-4 px-4 py-2 rounded-full text-white bg-primary hover:bg-primary/70 cursor-pointer ${isSending &&
                    'cursor-not-allowed'}`}
                  disabled={isSending}
                >
                  {t('form.whitepaperSubmit')}
                </button>
              </form>
            )}
          </AnimationWrapper>
        </div>
      </div>
    </div>
  );
}
